<template>
  <TransitionFadeComponent>
    <router-view class="content"></router-view>
  </TransitionFadeComponent>
</template>

<script>
import TransitionFadeComponent from "@/components/Transitions/Fade";

export default {
  name: "MainContentComponent",
  components: {
    TransitionFadeComponent
  }
};
</script>

<style scoped lang="scss"></style>
