<template>
  <div class="content-fluid">
    <main class="wrapper-main">
      <!-- <MainHeaderComponent /> -->
      <MainContentComponent />
    </main>
  </div>
</template>

<script>
// import MainHeaderComponent from "./MainHeaderComponent";
import MainContentComponent from "./MainContentComponent";

export default {
  name: "MainLayout",
  data: () => ({}),
  components: {
    // MainHeaderComponent,
    MainContentComponent
  }
};
</script>

<style scoped lang="scss">
main {
  min-height: 100vh;
}
</style>
